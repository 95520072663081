jQuery(document).ready(function($){

    $('.home-slider').slick({
        dots: true,
        speed: 1000,
        infinite: true,
        autoplay: false,
        cssEase: 'ease-out',
        arrows: true,
        fade: true,
        prevArrow: '<button type="button" class="slick-prev"><i class="icon-arrow-left"></i></button>',
        nextArrow: '<button type="button" class="slick-next"><i class="icon-arrow-right"></i></button>',
    });

    $('.product__slider--main').slick({
        dots: false,
        speed: 1000,
        infinite: true,
        autoplay: false,
        cssEase: 'ease-out',
        arrows: false,
        fade: true,
        // responsive: [
        //     {
        //         breakpoint: 640,
        //         settings: "unslick",
        //     },
        // ],
    });

    $('.product__slider--nav').slick({
        dots: false,
        speed: 1000,
        infinite: true,
        autoplay: false,
        cssEase: 'ease-out',
        arrows: false,
        fade: false,
        slidesToShow: 6,
        slidesToScroll: 1,
        centerMode: false,
        focusOnSelect: true,
        asNavFor: '.product__slider--main',
        // responsive: [
        //     {
        //         breakpoint: 992,
        //         settings: {
        //             slidesToShow: 4,
        //         }
        //     },
        //     {
        //         breakpoint: 720,
        //         settings: {
        //             slidesToShow: 2,
        //             vertical: false,
        //         }
        //     },
        // ],
    });


});
